import { makeStyles } from "@material-ui/core";
import { getDateRange } from "helpers/getDateRange";
import { INTERVAL } from "pages/IndexMarket/constants";
import { useMemo } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";

const tickFormatter = (time, interval) => {
  const isShowTime = [INTERVAL.DAY, INTERVAL.WEEK].includes(interval.value);
  const date = new Date(time * 1000);
  return {
    date: date.toLocaleDateString("en-US", { month: "short", day: "numeric" }),
    time: isShowTime
      ? date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
      : undefined,
  };
};

const useStyles = makeStyles(() => ({
  tooltipContainer: {
    backgroundColor: "#2E2E2E",
    padding: "10px",
    borderRadius: "5px",
    color: "#E2E2E2",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    marginBottom: 10,
  },
  label: {
    textAlign: "center",
    margin: 0,
    fontWeight: "bolder",
    letterSpacing: 1,
  },
  lineSeparator: {
    width: "100%",
    height: 4,
    borderRadius: 4,
  },
  m_0: {
    margin: 0,
  },
}));

const CustomXAxis = ({ payload, x, y, interval }) => {
  const { date, time } = tickFormatter(payload.value, interval);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={20} y={0} dy={16} textAnchor="end" fill="#666">
        <tspan x="20" dy="1.2em">
          {date}
        </tspan>
        <tspan x="30" dy="1.2em">
          {time}
        </tspan>
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, actColor }) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    const date = new Date(label * 1000);
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return (
      <div className={classes.tooltipContainer}>
        <div className={classes.labelContainer}>
          <p className={classes.label}>Lender APY</p>
          <div className={classes.lineSeparator} style={{ background: actColor }} />
        </div>
        <p className={classes.m_0}>{`Time: ${formattedDate}`}</p>
        <p className={classes.m_0}>{`Value: ${(parseFloat(payload[0].value) || 0).toFixed(2)}%`}</p>
      </div>
    );
  }

  return null;
};

const LenderChart = ({ data, activeTab, interval }) => {
  const filteredData = useMemo(() => {
    const allTicks = data[0][0];
    const filtered = allTicks.filter(
      (item) => item.time >= getDateRange(interval.value).getTime() / 1000
    );
    return filtered;
  }, [data, interval.value]);

  const maxValue = useMemo(() => Math.max(...filteredData.map((d) => d.value)), [filteredData]);

  return (
    <ResponsiveContainer width="100%" height={600}>
      <AreaChart data={filteredData} margin={{ top: 0, right: 40, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorLenderAPY" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={activeTab.color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={activeTab.color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="time"
          tick={<CustomXAxis interval={interval} />}
          scale="time"
          height={60}
          interval="preserveStartEnd"
        />
        <YAxis domain={[0, Math.ceil(maxValue)]} tickFormatter={(value) => `${value}%`} />
        <Brush
          tickFormatter={(value) => tickFormatter(value, interval)?.date}
          dataKey="time"
          height={30}
          stroke={activeTab.color}
        />
        <CartesianGrid strokeDasharray="3" vertical={false} stroke="#454545" />
        <Tooltip content={<CustomTooltip actColor={activeTab.color} />} />
        <Area
          type="monotone"
          dataKey="value"
          stroke={activeTab.color}
          fillOpacity={1}
          fill="url(#colorLenderAPY)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LenderChart;
