import { providers } from "ethers";
import {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
  sepolia,
} from "wagmi/chains";

// Customize AlchemyProvider to supported Base chain
providers.AlchemyProvider.getUrl = (network, apiKey) => {
  let host = null;
  switch (network.name) {
    case "homestead":
      host = "eth-mainnet.alchemyapi.io/v2/";
      break;
    case "goerli":
      host = "eth-goerli.g.alchemy.com/v2/";
      break;
    case "matic":
      host = "polygon-mainnet.g.alchemy.com/v2/";
      break;
    case "maticmum":
      host = "polygon-mumbai.g.alchemy.com/v2/";
      break;
    case "arbitrum":
      host = "arb-mainnet.g.alchemy.com/v2/";
      break;
    case "arbitrum-goerli":
      host = "arb-goerli.g.alchemy.com/v2/";
      break;
    case "optimism":
      host = "opt-mainnet.g.alchemy.com/v2/";
      break;
    case "optimism-goerli":
      host = "opt-goerli.g.alchemy.com/v2/";
      break;
    case "base-sepolia":
      host = "base-sepolia.g.alchemy.com/v2/";
      break;
    case "base":
      host = "base-mainnet.g.alchemy.com/v2/";
      break;
    case "zksync-era":
      host = "zksync-mainnet.g.alchemy.com/v2/";
      break;
    default:
      throw new Error("unsupported network", "network", network);
  }

  return {
    allowGzip: true,
    url: `https://${host}${apiKey}`,
    throttleCallback: () => Promise.resolve(true),
  };
};

providers.InfuraProvider.getUrl = (network, apiKey) => {
  let host = null;
  switch (network ? network.name : "unknown") {
    case "homestead":
      host = "mainnet.infura.io";
      break;
    case "goerli":
      host = "goerli.infura.io";
      break;
    case "sepolia":
      host = "sepolia.infura.io";
      break;
    case "matic":
      host = "polygon-mainnet.infura.io";
      break;
    case "maticmum":
      host = "polygon-mumbai.infura.io";
      break;
    case "optimism":
      host = "optimism-mainnet.infura.io";
      break;
    case "optimism-goerli":
      host = "optimism-goerli.infura.io";
      break;
    case "arbitrum":
      host = "arbitrum-mainnet.infura.io";
      break;
    case "arbitrum-goerli":
      host = "arbitrum-goerli.infura.io";
      break;
    case "base-sepolia":
      host = "base-sepolia.infura.io";
      break;
    case "base":
      host = "base-mainnet.infura.io";
      break;
    default:
      throw new Error("unsupported network", "network", network);
  }

  const connection = {
    allowGzip: true,
    url: `https://${host}/v3/${apiKey.projectId}`,
    throttleCallback: () => Promise.resolve(true),
  };

  if (apiKey.projectSecret != null) {
    connection.user = "";
    connection.password = apiKey.projectSecret;
  }

  return connection;
};

const base = {
  id: 8453,
  network: "base",
  name: "Base",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: {
      http: ["https://mainnet.base.org"],
    },
    public: {
      http: ["https://base.llamarpc.com"],
    },
    alchemy: {
      http: ["https://base-mainnet.g.alchemy.com/v2"],
      webSocket: ["wss://base-mainnet.g.alchemy.com/v2"],
    },
    infura: {
      http: ["https://base-mainnet.infura.io/v3"],
      webSocket: ["wss://base-mainnet.infura.io/ws/v3"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Basescan",
      url: "https://basescan.org/",
      apiUrl: "https://api.basescan.org/api",
    },
    default: {
      name: "Basescan",
      url: "https://basescan.org/",
      apiUrl: "https://api.basescan.org/api",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 5022,
    },
  },
  testnet: false,
};

const baseSepolia = {
  id: 84532,
  network: "base-sepolia",
  name: "Base Sepolia",
  nativeCurrency: { name: "Sepolia Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: {
      http: ["https://sepolia.base.org"],
    },
    public: {
      http: ["https://sepolia.base.org"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Basescan",
      url: "https://sepolia.basescan.org",
      apiUrl: "https://api-sepolia.basescan.org/api",
    },
    default: {
      name: "Basescan",
      url: "https://sepolia.basescan.org",
      apiUrl: "https://api-sepolia.basescan.org/api",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 1059647,
    },
  },
  testnet: true,
};

const zkSync = {
  id: 324,
  name: "zkSync Era",
  network: "zksync-era",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.era.zksync.io"],
      webSocket: ["wss://mainnet.era.zksync.io/ws"],
    },
    public: {
      http: ["https://mainnet.era.zksync.io"],
      webSocket: ["wss://mainnet.era.zksync.io/ws"],
    },
    alchemy: {
      http: ["https://eth-mainnet.g.alchemy.com/v2"],
      webSocket: ["wss://eth-mainnet.g.alchemy.com/v2"],
    },
  },
  blockExplorers: {
    default: {
      name: "zkExplorer",
      url: "https://explorer.zksync.io",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 5022,
    },
  },
  testnet: false,
};

export {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
  zkSync,
  sepolia,
  base,
  baseSepolia,
};
