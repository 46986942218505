import { subHours, subDays, subWeeks, subMonths, subYears } from "date-fns";
import { INTERVAL } from "pages/IndexMarket/constants";

const HOURS = 1;
const DAY = 1;
const WEEK = 1;
const MONTH = 1;
const YEAR = 1;
const THREE_MONTHS = 3;
const SIX_MONTHS = 6;

export const getDateRange = (interval) => {
  const currentDate = new Date();

  switch (interval) {
    case INTERVAL.DAY:
      return subDays(currentDate, DAY);
    case INTERVAL.WEEK:
      return subWeeks(currentDate, WEEK);
    case INTERVAL.MONTH:
      return subMonths(currentDate, MONTH);
    case INTERVAL.THREE_MONTHS:
      return subMonths(currentDate, THREE_MONTHS);
    case INTERVAL.SIX_MONTHS:
      return subMonths(currentDate, SIX_MONTHS);
    case INTERVAL.YEAR:
      return subYears(currentDate, YEAR);
    case INTERVAL.ALL:
      return new Date(0);
    default:
      return subHours(currentDate, HOURS);
  }
};
