import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { linePropType } from "types/market";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: "#000000",
    borderRadius: 4,
    color: "#FFFFFF",
    // "&:hover, &.MuiChip-clickable:focus": {
    //   backgroundColor: "#bdbdbd",
    //   color: "#000000",
    // },
  },
  chipColor: {
    width: 4,
    height: 14,
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
  chipLabel: {
    fontSize: 16,
  },
}));

const ChartChip = ({ line }) => {
  const classes = useStyles();
  const chipRef = useRef(null);

  // useEffect(() => {
  //   if (chipRef.current && line.title === "Lender APY") {
  //     chipRef.current.focus();
  //   }
  // }, [line]);

  return (
    <Chip
      ref={chipRef}
      label={
        <Box display="flex" alignItems="center">
          {/* <div style={{ backgroundColor: line.color }} className={classes.chipColor} /> */}
          <span className={classes.chipLabel}>{line.title}</span>
        </Box>
      }
      className={classes.chip}
      // clickable={false}
      //   onClick={() => handleTabChange(line)}
    />
  );
};

ChartChip.propTypes = {
  line: linePropType.isRequired,
};

export default ChartChip;
