import { gql } from "@apollo/client";
import { get } from "lodash";
import { QueryGraphEntity, SELECT } from "pages/IndexMarket/constants";

import { useQuery } from "react-query";
import { getClient } from "utils/client/graphql.client";

export const GET_LENDER_APY = gql`
  query LenderHistory($skip: Int!, $startAt: BigInt!, $lendingToken: String, $limit: Int = 1000) {
    lenderAPYHistories(
      first: $limit
      orderBy: date
      orderDirection: desc
      skip: $skip
      where: { lendingTokenAddress: $lendingToken, date_gte: $startAt }
    ) {
      amount
      date
      id
      lendingTokenAddress
    }
  }
`;

const handleDataChartLine = (data = [], { color }) => {
  if (data.length <= 0) return [];
  let tmp = +get(data, [data.length - 1, "date"], Number.MAX_VALUE);
  let startIndex = data.length - 1;
  const lines = [
    {
      kind: SELECT.totalAmount,
      dots: [],
    },
  ];

  while (tmp < Date.now() / 1000) {
    const val = get(data, [startIndex, "amount"], 0);
    lines[0].dots.push({
      time: tmp,
      value: val,
      color,
    });
    tmp += 1800;
    while (tmp > get(data, [startIndex, "date"], Number.MAX_VALUE) && startIndex > 1) {
      startIndex -= 1;
    }
  }
  const val = get(data, [0, "amount"], 0);

  lines[0].dots.push({
    time: Date.now() / 1000,
    value: val,
    color,
  });

  return [lines[0].dots];
};

export const handleGetLastValue = (data) => {
  if (data) {
    const objectValueTab = {};
    Object.entries(data).forEach((e) => {
      const arrayItem = data[e[0]];
      // eslint-disable-next-line prefer-destructuring
      objectValueTab[e[0]] = arrayItem[0];
    });
    return objectValueTab;
  }
  return [];
};

const handleDataChart = (activeLines = [], data) => {
  const arrayData = activeLines.map((item) =>
    handleDataChartLine(get(data, QueryGraphEntity[item.value]), item)
  );
  return arrayData;
};

const getClientByChainNumber = (chainId) => getClient(`0x${Number(chainId).toString(16)}`);

export const useLenderChart = (lendingToken, chainId, actTab) => {
  const client = getClientByChainNumber(chainId);

  const activeLines = [actTab];
  const activeTab = actTab;

  const { data, refetch, isLoading } = useQuery(["get-all-market-data", lendingToken], async () => {
    let hasNextPage = true;
    let cursor = 0;
    const allResults = {};

    while (hasNextPage) {
      // eslint-disable-next-line no-await-in-loop
      const { data: marketData } = await client.query({
        query: GET_LENDER_APY,
        variables: {
          lendingToken,
          skip: cursor,
          startAt: 0,
          limit: 1000,
        },
      });

      hasNextPage = false;
      // eslint-disable-next-line no-loop-func
      Object.keys(marketData).forEach((key) => {
        if (!allResults[key]) {
          allResults[key] = [];
        }
        allResults[key] = allResults[key].concat(marketData[key]);
        if (marketData[key].length === 1000) {
          hasNextPage = true;
        }
      });
      cursor += 1000;
      if (cursor > 5000) {
        hasNextPage = false;
      }
    }
    return allResults;
  });

  return {
    data: handleDataChartLine(get(data, QueryGraphEntity[activeTab.value]), activeTab),
    fullData: handleDataChart(activeLines, data),
    refetch,
    isLoading,
    valueTab: handleGetLastValue(data),
  };
};
