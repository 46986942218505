import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { INTERVALS_APY } from "pages/IndexMarket/constants";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #444",
    borderRadius: "8px",
    backgroundColor: "#000",
    padding: "3px",
  },
  button: {
    flex: 1,
    color: "#FFFFFF",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: "6px",
    background: "#000",
    "&:hover": {
      backgroundColor: "#ec333b",
    },
    padding: 5,
    margin: "1px 1px",
  },
  activeButton: {
    backgroundColor: "#ec333b",
  },
}));

const IntervalSelector = ({ onIntervalChange }) => {
  const classes = useStyles();
  const [interval, setInterval] = useState(INTERVALS_APY[3]);

  useEffect(() => {
    onIntervalChange(interval);
  }, [interval, onIntervalChange]);

  const handleChange = (tab) => {
    setInterval(tab);
  };

  return (
    <Box className={classes.container}>
      {INTERVALS_APY.map((tab) => (
        <Button
          key={tab}
          onClick={() => handleChange(tab)}
          className={`${classes.button} ${interval === tab ? classes.activeButton : ""}`}
        >
          {tab.name}
        </Button>
      ))}
    </Box>
  );
};

export default IntervalSelector;
