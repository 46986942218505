import Paper from "@material-ui/core/Paper";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useLenderChart } from "hooks/query/graphQL/useLenderChart";
import { Spinner } from "components";
import { CHARTS } from "pages/IndexMarket/constants";
import { useMemo, useState } from "react";
import { getNetworkLogoById } from "utils/network";
import LenderChart from "./LenderChart";
import ChartChip from "./ChartChip";
import IntervalSelector from "./IntervalSelector";

const useStyles = makeStyles((theme) => ({
  chartBox: {
    minHeight: 465,
    marginTop: theme.spacing(2),
  },
  chartContainer: {
    marginTop: "1rem",
    padding: theme.breakpoints.up("md") ? "0 24px" : "0 12px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  chainLogo: {
    width: 35,
    height: 35,
  },
  selectorContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  selector: {
    marginRight: "60px",
  },
}));

const LenderAPYChart = ({ address, chainId }) => {
  const classes = useStyles();
  const chain = useMemo(() => `0x${Number(chainId).toString(16)}`, [chainId]);
  const chainLogo = useMemo(() => {
    const Logo = getNetworkLogoById(chain);
    if (Logo) return <Logo className={classes.chainLogo} />;
    return null;
  }, [chain, classes.chainLogo]);

  const [activeTab, setActiveTab] = useState(CHARTS[0]);
  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const [interval, setInterval] = useState("1D");
  const handleIntervalChange = (newInterval) => {
    setInterval(newInterval);
  };

  const { isLoading = true, fullData } = useLenderChart(address, chainId, activeTab);

  const renderChart = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return <LenderChart data={fullData} activeTab={activeTab} interval={interval} />;
  };

  return (
    <Paper>
      <Box className={classes.chartContainer}>
        <Box>{chainLogo}</Box>

        {CHARTS.map((line) => (
          <Box key={line.value} sx={{ margin: "0.5rem" }}>
            <ChartChip line={line} handleTabChange={handleTabChange} />
          </Box>
        ))}
      </Box>
      <Box className={classes.selectorContainer}>
        <Box className={classes.selector}>
          <IntervalSelector onIntervalChange={handleIntervalChange} />
        </Box>
      </Box>
      <Box
        className={classes.chartBox}
        sx={{
          padding: {
            xs: "24px 12px",
            md: "24px",
          },
        }}
      >
        {renderChart()}
      </Box>
    </Paper>
  );
};

export default LenderAPYChart;
