/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import numeral from "numeral";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box, CircularProgress } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useBlendingTokenInfo, useTokenInfo } from "hooks/contract/useTokenStats";
import { getNetworkLogoById } from "utils/network";
import LenderAPYChart from "components/LenderAPYChart/LenderAPYChart";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  title: {
    fontSize: "26px",
    marginBottom: 20,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  stats: {
    cursor: "pointer",
    "&:hover": {
      background: "#5c5c5c",
    },
    background: "#454545",
    border: "3px solid #5c5c5c",
    borderRadius: "2px",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    justifyContent: "space-between",
    gap: 0,
    flexWrap: "wrap",
    marginBottom: 5,
    [theme.breakpoints.down("md")]: {
      gap: 30,
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      gap: 15,
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  statsLoading: {
    justifyContent: "center !important",
  },
  statsItem: {
    [theme.breakpoints.down("sm")]: {
      "& p": {
        textAlign: "center",
      },
    },
  },
  chain: {
    width: "16%",
    minWidth: 150,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  statsItemTitle: {
    fontSize: "1rem",
    color: "#a5a8b6",
  },
  statsItemUsdValue: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  statsItemChainValue: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  statsItemTokenValue: {
    fontSize: "0.875rem",
    color: "#a5a8b6",
  },
  chainLogo: {
    width: 35,
    height: 35,
  },
  chart: {
    marginBottom: 10,
  },
  fadeIn: {
    opacity: 0,
    animation: `$fadeIn 0.8s forwards`,
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));

const CoinStat = ({ address, name, price, symbol, network }) => {
  const classes = useStyles();
  const [showChart, setShowChart] = useState(false);
  const chainId = useMemo(() => `0x${Number(network?.id).toString(16)}`, [network]);
  const { debtLimitUds, lvr, loading: loading1 } = useTokenInfo(address, chainId);
  const {
    totalSupplyUsd,
    totalBorrowsUsd,
    borrowApy,
    lenderApy,
    loading: loading2,
  } = useBlendingTokenInfo(address, chainId);

  const utilization = useMemo(
    () => totalBorrowsUsd / totalSupplyUsd,
    [totalBorrowsUsd, totalSupplyUsd]
  );
  const isLoading = useMemo(() => loading1 || loading2, [loading1, loading2]);

  const formatLargeValue = (value) => {
    const maxLength = 6;
    // Determine the abbreviation
    const formatted = numeral(value).format("0a");
    if (formatted.toLowerCase() === "nan") {
      return 0;
    }

    const abbreviation = formatted.replace(/[\d.]/g, "");

    // Remove the abbreviation to focus on the number part
    const numberOnly = formatted.replace(/[^\d.]/g, "");

    // Calculate the number of decimal places required
    const decimalPlaces = Math.max(0, maxLength - numberOnly.length - abbreviation.length);

    // Format the number with the calculated decimal places
    const formatString = `0.${"0".repeat(decimalPlaces)}a`;

    return numeral(value).format(formatString).toUpperCase();
  };
  const formatTokenValue = (value) => {
    const format = Number(value) > 1000 ? "0,0.[00]a" : "0,0.[0000]";
    const formatted = numeral(value).format(format);
    if (formatted.toLowerCase() === "nan") {
      return 0;
    }
    return formatted.toUpperCase();
  };
  const formatPercentValue = (value) => {
    const formatted = numeral(value * 100).format("0.[00]");
    if (formatted.toLowerCase() === "nan") {
      return 0;
    }
    return formatted;
  };

  const formatValue = (value) => numeral(value).format("0,0.[00]");

  const chainLogo = useMemo(() => {
    const Logo = getNetworkLogoById(chainId);
    if (Logo) return <Logo className={classes.chainLogo} />;
    return null;
  }, [chainId, classes.chainLogo]);

  const handleToggleChart = () => {
    setShowChart((prev) => !prev);
  };

  return (
    <Box className={classes.container}>
      {isLoading ? (
        <Grid className={[classes.stats, classes.statsLoading]}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid className={classes.stats} onClick={handleToggleChart}>
            <Box className={[classes.statsItem, classes.chain]}>
              <Typography className={classes.statsItemTitle}>Chain</Typography>
              <Box className={classes.statsItemChainValue}>
                {chainLogo}
                <Typography className={classes.statsItemUsdValue}>{network?.name}</Typography>
              </Box>
            </Box>
            <Box className={classes.statsItem}>
              <Typography className={classes.statsItemTitle}>Lender APY</Typography>
              <Typography className={classes.statsItemUsdValue}>
                {formatValue(lenderApy)}%
              </Typography>
            </Box>
            <Box className={classes.statsItem}>
              <Typography className={classes.statsItemTitle}>Borrow APY</Typography>
              <Typography className={classes.statsItemUsdValue}>
                {formatValue(borrowApy)}%
              </Typography>
            </Box>
            <Box className={classes.statsItem}>
              <Typography className={classes.statsItemTitle}>Total Supplied</Typography>
              <Typography className={classes.statsItemUsdValue}>
                ${formatLargeValue(totalSupplyUsd)}
              </Typography>
              <Typography className={classes.statsItemTokenValue}>
                {formatTokenValue(totalSupplyUsd / price)} {symbol}
              </Typography>
            </Box>
            <Box className={classes.statsItem}>
              <Typography className={classes.statsItemTitle}>Total Borrowed</Typography>
              <Typography className={classes.statsItemUsdValue}>
                ${formatLargeValue(totalBorrowsUsd)}
              </Typography>
              <Typography className={classes.statsItemTokenValue}>
                {formatTokenValue(totalBorrowsUsd / price)} {symbol}
              </Typography>
            </Box>
            <Box className={classes.statsItem}>
              <Typography className={classes.statsItemTitle}>LVR</Typography>
              <Typography className={classes.statsItemUsdValue}>
                {formatPercentValue(lvr)}%
              </Typography>
            </Box>
            <Box className={classes.statsItem}>
              <Typography className={classes.statsItemTitle}>Debt Limit</Typography>
              <Typography className={classes.statsItemUsdValue}>
                ${formatLargeValue(debtLimitUds)}
              </Typography>
              <Typography className={classes.statsItemTokenValue}>
                {formatTokenValue(debtLimitUds / price)} {symbol}
              </Typography>
            </Box>
            <Box className={classes.statsItem}>
              <Typography className={classes.statsItemTitle}>Utilization</Typography>
              <Typography className={classes.statsItemUsdValue}>
                {formatPercentValue(utilization)}%
              </Typography>
            </Box>
          </Grid>
          {showChart && (
            <Box className={`${classes.fadeIn} ${classes.chart}`}>
              <LenderAPYChart address={address} chainId={network.id} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

CoinStat.propTypes = {
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

CoinStat.defaultProps = {};

export default CoinStat;
